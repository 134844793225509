export default {
    title: 'Bot erstellen',
    bot: 'Bot',
    name: 'Name',
    telegram_chat_id: 'Telegram Chat ID',
    type: 'Typ',
    class: 'Klasse',
    config: 'Konfiguration',
    active: 'Aktiv',

    createBot: 'Bot hinzufügen',
    deleteBot: 'Bot löschen',

    botNotDeletable: 'Dieser Bot kann nicht gelöscht werden.',
    createBotSuccess: 'Der Bot wurde erfolgreich erstellt.',
    deleteBotSuccess: 'Der Bot wurde erfolgreich gelöscht.',
    types: {
        telegram: 'Telegram',
    }
}