export default {
    home: 'Home',
    dashboard: 'Dashboard',
    users: 'Nutzer',
    organisations: 'Organisationen',
    settings: 'Einstellungen',
    myProfile: 'Mein Profil',
    information: 'Informationen',
    secret: 'Geheimnis',
    dungeon: 'Dungeon',
    overview: 'Input-Übersicht',
    nothingToSeeHere: 'Hier gibt es nichts zu sehen 🙈',
    escapeRoom: '//farbcode Dungeon Escape! 🏰',
    logout: 'Logout',
    loginProviders: 'Login Methoden',
    mailTemplates: 'Mail-Vorlagen',
    bots: 'Bots',
}
