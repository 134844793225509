import table from './table'

export default {
    bot: 'Bot',


    providerId: 'Bot {name}',

    createBot: 'Create bot',
    deleteBot: 'Delete bot',
    deleteBotInfo:
        'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    noOrganisationFound: 'No organisation found.',
    botAlreadyExists: 'A login method with this bot already exists.',
    redirect_url: 'Redirect URL',

    bots: {
        google: 'Google',
        azure: 'Microsoft'
    },

    table
}