import { default as indexTnITg4LPvzMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/auth/index.vue?macro=true";
import { default as indexis4HPjngFWMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexbBU76cSyYLMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/index.vue?macro=true";
import { default as organisationBaseDataimeNEGGkt8Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93BWoUSWeQIbMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/[id].vue?macro=true";
import { default as index3LyO4Ga52kMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/index.vue?macro=true";
import { default as organisations4YDhA870VlMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations.vue?macro=true";
import { default as dungeon6RcOKpkW0nMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsYhz2E8oNF8Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexpweh0CCCjQMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index.vue?macro=true";
import { default as languageOZbdaettaMMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/language.vue?macro=true";
import { default as _91id_93zWjW6PHO7zMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexNQyVUM6jyCMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93sBYJqW03wnMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as index2a4e5zJItXMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsnS9VvyA2aVMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings.vue?macro=true";
import { default as _91id_93dREDuV6wXrMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/bots/[id].vue?macro=true";
import { default as indexNifNOn23VPMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/bots/index.vue?macro=true";
import { default as messagesD9SIwhoKx6Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/messages.vue?macro=true";
import { default as ordersa9LXBJ0dpYMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/orders.vue?macro=true";
import { default as userActivitieswHWhCTrmP6Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataPc5nUNtdRcMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsepBMCd8c7NMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93rxNOJOMwguMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id].vue?macro=true";
import { default as indexm1xxWASUlcMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/index.vue?macro=true";
import { default as usersWZlyyeViyNMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93fPZ0NquYMEMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexTnITg4LPvzMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexis4HPjngFWMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisations4YDhA870VlMeta?.name,
    path: "/organisations",
    meta: organisations4YDhA870VlMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93BWoUSWeQIbMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: index3LyO4Ga52kMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsnS9VvyA2aVMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93zWjW6PHO7zMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexNQyVUM6jyCMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93sBYJqW03wnMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: index2a4e5zJItXMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "telegram-bots-id",
    path: "/telegram/bots/:id()",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/bots/[id].vue").then(m => m.default || m)
  },
  {
    name: "telegram-bots",
    path: "/telegram/bots",
    meta: indexNifNOn23VPMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/bots/index.vue").then(m => m.default || m)
  },
  {
    name: "telegram-messages",
    path: "/telegram/messages",
    meta: messagesD9SIwhoKx6Meta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/messages.vue").then(m => m.default || m)
  },
  {
    name: "telegram-orders",
    path: "/telegram/orders",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/telegram/orders.vue").then(m => m.default || m)
  },
  {
    name: usersWZlyyeViyNMeta?.name,
    path: "/users",
    meta: usersWZlyyeViyNMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93rxNOJOMwguMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDataPc5nUNtdRcMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexm1xxWASUlcMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93fPZ0NquYMEMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250213214024/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]